import { environment } from '../../../environments/environment';

export class AppConfigData {
	//ConfigData
	static configUrl: string = environment.ripple_core_baseUrl + 'configuration/get-config-data';

	static Razorpay_KeyId: string = environment.Razorpay_KeyId;

	//Auth
	static loginUrl: string = environment.ripple_metering_baseurl + 'account/login';
	static logOutUrl: string = environment.ripple_metering_baseurl + 'account/logout';
	static sendOtpUrl: string = environment.ripple_metering_baseurl + 'account/send-otp';
	static forgotPwdUrl: string = environment.ripple_metering_baseurl + 'account/forgot-password';
	static mewPasswordUrl: string = environment.ripple_metering_baseurl + 'account/set-new-password';

	static resetPwdUrl: string = environment.ripple_metering_baseurl + 'account/reset-password';
	static getProfileUrl: string = environment.ripple_metering_baseurl + 'account/user-profile';
	static updateProfileUrl: string = environment.ripple_metering_baseurl + 'User/edit-profile';
	static updatePwdUrl: string = environment.ripple_metering_baseurl + 'account/change-password';
	static refreshToken: string = environment.ripple_metering_baseurl + 'account/refresh-token';
	static pwdRegExp: string = environment.ripple_metering_baseurl + 'account/get-password-pattern';

	//Faqs
	static getFaqsUrl: string = environment.ripple_projects_baseUrl + 'faq/get-faqs';
	static createFaqsUrl: string = environment.ripple_projects_baseUrl + 'FAQ/create-faq';
	static updateFaqsUrl: string = environment.ripple_projects_baseUrl + 'faq/update-faq/';
	static deleteFaqsUrl: string = environment.ripple_projects_baseUrl + 'faq/delete-faq/';
	static downloadFaqsImagesZipUrl: string = environment.ripple_projects_baseUrl + 'faq/download-attachments/';

	//Masters
	static getMastersUrl: string = environment.ripple_core_baseUrl + 'master/get-all-masters';
	static createMastersUrl: string = environment.ripple_core_baseUrl + 'master/create-master';
	static updateMastersUrl: string = environment.ripple_core_baseUrl + 'master/edit-master/';
	static deleteMastersUrl: string = environment.ripple_core_baseUrl + 'master/delete-master/';

	//Suppliers
	static getSuppliersUrl: string = environment.ripple_core_baseUrl + 'supplier/get-all-suppliers';
	static createSuppliersUrl: string = environment.ripple_core_baseUrl + 'supplier/create-supplier';
	static updateSuppliersUrl: string = environment.ripple_core_baseUrl + 'supplier/edit-supplier/';
	static deleteSuppliersUrl: string = environment.ripple_core_baseUrl + 'supplier/delete-supplier/';

	//Projects
	static getProjectUrl: string = environment.ripple_projects_baseUrl + 'Project/get-all-projects';
	static createProjectUrl: string = environment.ripple_projects_baseUrl + 'Project/create-project';
	static updateProjectUrl: string = environment.ripple_projects_baseUrl + 'Project/edit-project/';
	static deleteProjectUrl: string = environment.ripple_projects_baseUrl + 'Project/delete-project/';
	static getUserProjects: string = environment.ripple_projects_baseUrl + 'Project/';

	//user
	static getUsersUrl: string = environment.ripple_metering_baseurl + 'user/get-all-users';
	static createUsersUrl: string = environment.ripple_metering_baseurl + 'user/create-user';
	static updateusersUrl: string = environment.ripple_metering_baseurl + 'user/edit-user/';
	static getUserByIdUrl: string = environment.ripple_metering_baseurl + 'user/';
	static deleteUsersUrl: string = environment.ripple_metering_baseurl + 'user/delete-user/';
	static userStatusUrl: string = environment.ripple_metering_baseurl + 'User/set-status/';

	//Monitoring
	static getMonitoringUrl: string = environment.ripple_projects_baseUrl + 'monitoringdevice/monitoring-devices/';
	static createMonitoringUrl: string = environment.ripple_projects_baseUrl + 'monitoringdevice/create-monitoring-device';
	static updateMonitoringUrl: string = environment.ripple_projects_baseUrl + 'monitoringdevice/edit-monitoring-device/';
	static deleteMonitoringUrl: string = environment.ripple_projects_baseUrl + 'monitoringdevice/delete-monitoring-device/';
	static getListOfMappingUmdUrl: string = environment.ripple_projects_baseUrl + 'MonitoringDevice/get-monitorings-devices-umds';
	static MappingUmdUrl: string = environment.ripple_projects_baseUrl + 'MonitoringDevice/map-umd-monitoring-device/';
	static UnMappingUmdUrl: string = environment.ripple_projects_baseUrl + 'MonitoringDevice/unmap-umd-monitoring-device/';
	static getListOfUnMappingUmdUrl: string = environment.ripple_projects_baseUrl + 'UtilityConfiguration/get-unmapped-umds/';

	//GateWay
	static getGatewayUrl: string = environment.ripple_projects_baseUrl + 'gatewayinformation/get-gateway-information/';
	static createGatewayUrl: string = environment.ripple_projects_baseUrl + 'gatewayinformation/create-gateway-information';
	static updateGatewayUrl: string = environment.ripple_projects_baseUrl + 'gatewayinformation/edit-gateway-information/';
	static deleteGatewayUrl: string = environment.ripple_projects_baseUrl + 'gatewayinformation/delete-gateway-information/';
	static gatewayOnOff: string = environment.ripple_projects_baseUrl + 'gatewayinformation/set-status/';

	//consumers:
	static getConsumersUrl: string = environment.ripple_metering_baseurl + 'consumer/get-all-consumers';
	static createconsumerUrl: string = environment.ripple_metering_baseurl + 'consumer/create-consumer';
	static updateconsumerUrl: string = environment.ripple_metering_baseurl + 'consumer/edit-consumer/';
	static deleteconsumerUrl: string = environment.ripple_metering_baseurl + 'consumer/delete-consumer/';
	static getconsumerStatusIdUrl: string = environment.ripple_metering_baseurl + 'consumer/set-status/';

	static getConsumersRechargeHistoryUrl: string = environment.ripple_wallet_baseUrl + 'Consumer/get-consumer-recharge-history/';

	/* static getConsumersFaqUrl: string =
	environment.ripple_wallet_baseUrl + 'Consumer/get-consumer-faqs/'; */

	static getConsumersFaqUrl: string = environment.ripple_projects_baseUrl + 'faq/get-faqs';

	static saveconsumerfeedbackUrl: string = environment.ripple_projects_baseUrl + 'project/save-consumer-feedback';

	static getconsumptionHistoryUrl: string = environment.ripple_wallet_baseUrl + 'Consumer/get-consumer-consumption-history/';

	static getConsumerMeterDeatilsUrl: string = environment.ripple_wallet_baseUrl + 'Consumer/get-consumer-meter-details';


	static getConsumerMeterControlsUrl: string = environment.ripple_wallet_baseUrl + 'Consumer/get-consumer-meter-controls/';


	static getConsumerMeterOnOffControlsUrl: string = environment.ripple_projects_baseUrl + 'MetersConfiguration/get-on-off-history';


	static ConsumerupdateMeterStatusUrl: string = environment.ripple_wallet_baseUrl + 'Consumer/UpdateMeterStatus/';

	static consumerdueBillsUrl: string = environment.ripple_billing_baseUrl + 'ConsumerBills/get-due-bills/';

	static consumerBillsDetailsByIdUrl: string = environment.ripple_billing_baseUrl + 'ConsumerBills/get-bill-details/';

	static consumerBillsHistoryUrl: string = environment.ripple_billing_baseUrl + 'ConsumerBills/get-bills-history/';

	static getInvoicehistory: string = environment.ripple_billing_baseUrl + 'ConsumerBills/get-invoice-history/';


	static updatesortorderUrl: string = environment.ripple_wallet_baseUrl + 'Consumer/update-sort-order';

	//https://ripple-wallet.azurewebsites.net/v1/Consumer/update-sort-order
	/*  environment.ripple_wallet_baseUrl +
   'Consumer/get-consumer-consumption-history/'; */

	//Residential Units:
	static getResidentialUnitUrl: string = environment.ripple_projects_baseUrl + 'residentialUnit/get-residential-units/';
	static getProjectUmdssUrl: string = environment.ripple_core_baseUrl + 'configuration/get-project-umds/';
	static createResidentialUnitUrl: string = environment.ripple_projects_baseUrl + 'residentialUnit/create-residential-unit';
	static getOccupationHstoryUrl: string = environment.ripple_projects_baseUrl + 'residentialUnit/get-occupants-history/';
	static getresidentialunitumdhistoryUrl: string = environment.ripple_projects_baseUrl + 'residentialUnit/get-residentialunit-umd-history/';

	static getResidentailUnitByIdUrl: string = environment.ripple_projects_baseUrl + 'residentialUnit/get-residential-unit/';
	static updateResidentailUnitdUrl: string = environment.ripple_projects_baseUrl + 'residentialUnit/edit-residential-unit/';

	//Umds

	static getUtilityUrl: string = environment.ripple_projects_baseUrl + 'utilityconfiguration/get-utilities/';
	static createUtilityUrl: string = environment.ripple_projects_baseUrl + 'utilityconfiguration/create-utility-config';
	static getUmdUrl: string = environment.ripple_projects_baseUrl + 'utilityconfiguration/get-umds/';
	static createUmdUrl: string = environment.ripple_projects_baseUrl + 'utilityconfiguration/create-umd';
	static updateUmdUrl: string = environment.ripple_projects_baseUrl + 'utilityconfiguration/edit-umd/';
	static deleteUmdUrl: string = environment.ripple_projects_baseUrl + 'utilityconfiguration/delete-umd/';
	static getelectricitystatus: string = environment.ripple_projects_baseUrl + 'utilityconfiguration/get-electricity-status/';
	static getutilitiesRateHistoryUrl: string = environment.ripple_projects_baseUrl + 'UtilityConfiguration/get-utility-history/';

	// Feedback

	static getconsumerfeedbackUrl: string = environment.ripple_projects_baseUrl + 'project/get-consumer-feedbacks/';
	static saveAgentAdminfeedbackUrl: string = environment.ripple_agent_admin_feedBack_baseUrl + 'feedback/save-feedback';
	static getAgentAdminfeedbackUrl: string = environment.ripple_agent_admin_feedBack_baseUrl + 'feedback/get-feedbacks';

	//Meters
	static getMetersUrl: string = environment.ripple_projects_baseUrl + 'MetersConfiguration/get-MeterDetails/';
	static getMetersConsumptionsDetailsUrl: string = environment.ripple_projects_baseUrl + 'MetersConfiguration/get-MeterConsumptionDetails/';
	static updateMeterStatusUrl: string = environment.ripple_projects_baseUrl + 'MetersConfiguration/update-meter-onff-request';
	static checkMeterStatusUrl: string = environment.ripple_projects_baseUrl + 'MetersConfiguration/check-meter-relay-status';

	// Billing Apis

	static getBillsUrl: string = environment.ripple_billing_baseUrl + 'Billing/get-Bills/';
	static getAdhocBillsUrl: string = environment.ripple_billing_baseUrl + 'Billing/get-AdhocBills/';
	static getManualBillUrl: string = environment.ripple_billing_baseUrl + 'Billing/get-ManualBills/';

	static createinvildBillsUrl: string = environment.ripple_projects_baseUrl + 'residentialUnit/create-residential-unit';
	static getBillsByIdUrl: string = environment.ripple_billing_baseUrl + 'Billing/get-BillDetailsById/';
	static updateInvalidBillUrl: string = environment.ripple_billing_baseUrl + 'Billing/updateInvalidBill/';
	static createBillUrl: string = environment.ripple_billing_baseUrl + 'Billing/CreateBill';
	static getResidentialUnitsUrl: string = environment.ripple_billing_baseUrl + 'Billing/get-ResidentialUnits/';
	static getBillReadingsInfoUrl: string = environment.ripple_billing_baseUrl + 'Billing/get-BillDetailsByResidentialUnit/';
	static getBillReadingsByMonthUrl: string = environment.ripple_billing_baseUrl + 'Billing/get-BillDetailsByMonth/';

	static getAdhocBillReadingsByMonthUrl: string = environment.ripple_billing_baseUrl + 'Billing/get-AdhocBillDetails';

	static paycashBillUrl: string = environment.ripple_billing_baseUrl + 'Billing/Pay-cash-bill';

	// Bill setting Apis
	static getBillSettingsUrl: string = environment.ripple_billing_baseUrl + 'BillSettings/get-billing-charge/';

	static getInviceUrl: string = environment.ripple_billing_baseUrl + 'invoiceads/get-invoice-ads';
	static createBillChargeUrl: string = environment.ripple_billing_baseUrl + 'billsettings/create-bill-charge';
	static updateBillChargeUrl: string = environment.ripple_billing_baseUrl + 'billsettings/update-bill-charge/';
	static deleteBillChargeUrl: string = environment.ripple_billing_baseUrl + 'billsettings/delete-bill-charge/';
	static createoptionalBillChargeUrl: string = environment.ripple_billing_baseUrl + 'billsettings/create-optional-bill-charge';
	static updateoptionalBillChargeUrl: string = environment.ripple_billing_baseUrl + 'billsettings/update-optional-bill-charge';
	static createinvoiceAdUrl: string = environment.ripple_billing_baseUrl + 'invoiceads/create-invoice-ad';
	static updateinvoiceAdUrl: string = environment.ripple_billing_baseUrl + 'invoiceads/update-invoice-ad/';
	static deleteInvoiceAdUrl: string = environment.ripple_billing_baseUrl + 'invoiceads/delete-invoice-ad/';
	static getOptionalBillChargeUrl: string = environment.ripple_billing_baseUrl + 'billsettings/get-optional-billing-charge/';

	// wallet Apis

	static getWallets: string = environment.ripple_wallet_baseUrl + 'Wallet/get-wallets/';
	static getWalletsHistory: string = environment.ripple_wallet_baseUrl + 'Wallet/get-wallet-histories/';
	static getRechargeHistory: string = environment.ripple_wallet_baseUrl + 'Wallet/get-recharge-histories/';

	static walletHistory: string = environment.ripple_wallet_baseUrl + 'Wallet/get-consumerwallet-history/';

	static walletPaybyCashUrl: string = environment.ripple_wallet_baseUrl + 'Payment/manual-recharge';

	//Templates
	static getTemplatesUrl: string = environment.ripple_core_baseUrl + 'configuration/DownloadBulkUploadTemplate';

	static downloadPaymentReceipt: string = environment.ripple_wallet_baseUrl + 'Payment/Download-Receipt/';

	static downloadInvoiceReceipt: string = environment.ripple_billing_baseUrl + 'Billing/Download-Invoice-Pdf/';


	//BulkUploads
	static umdBulkUpload: string = environment.ripple_projects_baseUrl + 'utilityconfiguration/Upload-Umd/';
	static consumerBulkUpload: string = environment.ripple_metering_baseurl + 'Consumer/Upload-Consumer/';
	static residentialBulkUpload: string = environment.ripple_projects_baseUrl + 'ResidentialUnit/Upload-ResidentialUnits/';

	//paymenet Gateway :
	static createOrder: string = environment.ripple_wallet_baseUrl + 'Payment/create-order';
	static paymentDetails: string = environment.ripple_wallet_baseUrl + 'Payment/upate-recharge-history';

	static createOrderIdForPoastpaidUser: string = environment.ripple_billing_baseUrl + 'ConsumerBills/create-order';

	static paybill: string = environment.ripple_billing_baseUrl + 'ConsumerBills/pay-bill';

	static paymentWalletFailure: string = environment.ripple_wallet_baseUrl + 'Payment/UpdatePaymentStatus/';
	static paymentBillingFailure: string = environment.ripple_billing_baseUrl + 'ConsumerBills/UpdatePaymentStatus/';

	// admin Dashobard

	static super_adimin_dashboard_data: string = environment.ripple_super_admin_baseUrl + 'DashBoard/get-superadmin-dashboard-data';

	static super_adimin_dashboard_Revenue_data: string = environment.ripple_super_admin_baseUrl + '/DashBoard/get-superadmin-dashboard-revenue-details';

	static super_adimin_dashboard_Revenue_data_BasedonDate: string = environment.ripple_super_admin_baseUrl + '/DashBoard/get-superadmin-dashboard-project-wise-revenue-details';

	//admin dashboard:

	static adimin_dashboard_data: string = environment.ripple_super_admin_baseUrl + '/DashBoard/get-projectadmin-dashboard-data/';

	static adimin_Flatwise_consumption_dashboard_data: string = environment.ripple_super_admin_baseUrl + '/DashBoard/get-projectadmin-flatwise-consumption-data';
	static adimin__consumption_dashboard_data: string = environment.ripple_super_admin_baseUrl + '/DashBoard/get-projectadmin-daily-consumption-data';

	// consumer dashboard

	static consumerpoatpaid_dashboard_data: string = environment.ripple_super_admin_baseUrl + '/DashBoard/get-consumer-dashboard-data';

	static consumerpoatpaidConsumption_dashboard_data: string = environment.ripple_super_admin_baseUrl + '/DashBoard/get-consumer-daily-consumption';

	static getconsumerConnectionsData: string = environment.ripple_super_admin_baseUrl + '/DashBoard/get-consumer-connections-data';


	// Reports
	static exportReportsUrl: string = environment.ripple_projects_baseUrl + 'Reports/battery-status';
	static getFlatNumbers: string = environment.ripple_projects_baseUrl + 'ResidentialUnit/get-flat-nos/';
	static getActivationStatusReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-activation-status-report';
	static getPrepaidConsumptionReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-prepaid-consumption-report';
	static getPostpaidConsumptionReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-postpaid-consumption-report';
	static getDeviceCommunicationnReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-device-communication-report';
	static getMeterReadingReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-meter-readings-report';
	static getBillGenerationReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-bill-generation-report';
	static getWalletBlanceGenerationReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-wallet-balance-report';
	static getWalletHistoryReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-wallet-history-report';

	static getsettlementReportUrl: string = environment.ripple_core_baseUrl + 'Reports/get-settlement-report';



	//Notifications
	static getNotificatonsUrl: string = environment.ripple_core_baseUrl + 'Notification/get-notifications';
	static clearNotificatonsUrl: string = environment.ripple_core_baseUrl + 'Notification/clear-notifications';
	static readAllNotificatonsUrl: string = environment.ripple_core_baseUrl + 'Notification/mark-all-as-read';
	static readNotificatonsUrl: string = environment.ripple_core_baseUrl + 'Notification/mark-as-read';

	// settlement apis ;
	static CreateTransferUrl: string = environment.ripple_wallet_baseUrl + 'Payment/Create-Transfer';
	static getSettlementDetailsUrl: string = environment.ripple_wallet_baseUrl + 'Payment/get-settlement-details';

	static CheckStatusTransferUrl: string = environment.ripple_wallet_baseUrl + 'Payment/check-transfer-status/';







}