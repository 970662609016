// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  Razorpay_KeyId: "rzp_test_wXw4aRZMqCsiAq",
    apiBaseUrl:
      'http://ripple-metering.centralindia.cloudapp.azure.com/api/gateway/',
  

  
    ripple_iot_auth_baseUrl: "https://ripple-iot-app-ffcfhca7ceh2ckcw.centralindia-01.azurewebsites.net/v1/",
    ripple_projects_baseUrl: "https://ripple-projects-app-gde0bcfmf4bsdmh3.centralindia-01.azurewebsites.net/v1/",
    ripple_metering_baseurl: "https://ripple-auth-app-b6eqaaeuesaqdtbr.centralindia-01.azurewebsites.net/v1/",
    ripple_core_baseUrl: "https://ripple-core-c6gka6e5babbd3g9.centralindia-01.azurewebsites.net/v1/",
    ripple_billing_baseUrl: "https://ripple-billing-app-hghbaacyhgbzdhhy.centralindia-01.azurewebsites.net/v1/",
    ripple_wallet_baseUrl: "https://ripple-wallet-app-fccrgcghhmhtgpge.centralindia-01.azurewebsites.net/v1/",
    ripple_agent_admin_feedBack_baseUrl: "https://ripple-core-c6gka6e5babbd3g9.centralindia-01.azurewebsites.net/v1/",
    ripple_super_admin_baseUrl: "https://ripple-core-c6gka6e5babbd3g9.centralindia-01.azurewebsites.net/v1/",
    
  
    production: false,
    defaultauth: 'fakebackend',
    firebaseConfig: {
      apiKey: "AIzaSyCBaVeG1HD_IJg2gCm_ga0LpY0A9vGLWRI",
      authDomain: "ripple-uat-dc90a.firebaseapp.com",
      projectId: "ripple-uat-dc90a",
      storageBucket: "ripple-uat-dc90a.appspot.com",
      messagingSenderId: "863438413341",
      appId: "1:863438413341:web:7f8abfb359727eff14f0f4",
      measurementId: "G-K4V73G90E1"
    },
  };
  
  /*
   * For easier debugging in development mode, you can import the following file
   * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
   *
   * This import should be commented out in production mode because it will have a negative impact
   * on performance if an error is thrown.
   */
  // import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
  