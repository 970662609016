import { LoaderService } from '../../../core/services/loader.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SharedService } from '../../shared.service';
import { Templates } from '../../constants/templatesNames';
import { AppConfigData } from '../../../core/config/appConfigData';
import { HttpResponse } from '@angular/common/http';
import { SweetAlertIcon } from '../../constants/enum';
import { FileRestrictions } from '@progress/kendo-angular-upload';

@Component({
  selector: 'app-rp-bulkupload',
  templateUrl: './rp-bulkupload.html',
  styleUrl: './rp-bulkupload.component.scss',
})
export class RpBulkUpoadComponent {
  uploadSaveUrl = 'saveUrl'; // should represent an actual API endpoint
  uploadRemoveUrl = 'removeUrl'; // should represent an actual API endpoint
  @Input() pageData: any;
  @Output() output: EventEmitter<any> = new EventEmitter<any>();
  templateTitle: string = '';
  file: any;
  pageId: string = '';
  requiredProjectId: boolean = false;
  url: string = '';

  disableFile: boolean = false;
  currentUser: any;
  constructor(
    private modalService: BsModalService,
    private loaderService: LoaderService,
    private sharedService: SharedService
  ) { }

  myRestrictions: FileRestrictions = {
    allowedExtensions: ['.xls', '.xlsx'],
  };
  dismissModal(): void {
    this.output.emit(this.pageData);
    this.modalService.hide();
  }

  downloadTemplate() {
    this.loaderService.setLoading(true)
    this.currentUser = JSON.parse(localStorage.getItem('currentUser') ?? '');
    this.sharedService.getTemplates(this.templateTitle, this.currentUser?.project.id).subscribe({
      next: (response: HttpResponse<Blob>) => {
        const blob = response.body;
        //  console.log('blob', response.text);
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = this.templateTitle; // Default filename
        if (blob) {
          this.loaderService.setLoading(false)
          // Ensure blob is not null
          console.log('Binary data received:', blob);
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(url);
        } else {
          this.loaderService.setLoading(false)
          console.error('Blob is null');
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.log('err', err);
      },
    });
  }

  onUploadSuccess(event: any) {
    console.log('Upload success', event.files[0]);
    this.file = event.files[0].rawFile;
    this.disableFile = false;
  }

  onUploadError(event: any) {
    console.log('Upload error', event);
    this.disableFile = false;
  }

  handleFileSelected(event: any) {
    console.log(event);
    this.disableFile = true;

    // Handle file selected logic
  }

  // uploadFile() {
  //   this.loaderService.setLoading(true);
  //   console.log(this.uploadSaveUrl);
  //   let url = '';
  //   if (
  //     this.pageData.title.toUpperCase() == this.pageId.toUpperCase() &&
  //     this.requiredProjectId
  //   ) {
  //     url = this.url + this.pageData.projectId;
  //   }
  //   this.sharedService.bulkUpload(url, this.file).subscribe({
  //     next: (response: any) => {
  //       console.log("santosh",response);
        
  //       this.loaderService.setLoading(false);
  //       const blob = response.body;
  //       const contentDisposition = response.headers.get('Content-Disposition');

  //       let filename = this.templateTitle; // Default filename`
  //       //console.log(response)
  //       // console.log(blob)
  //       // console.log(contentDisposition)
  //       const reader = new FileReader();
  //       reader.onload = () => {
  //         const result:any = reader.result; // Read result as a string
  //         console.log("Raw response:", result); // Log the raw response
  //         this.loaderService.setLoading(false);
  //         // Assuming the response is a simple text message
  //         //  result = JSON.parse(result)
  //         const parsedResult = JSON.parse(result);
  //   if (parsedResult?.message === "File processed successfully.") {
  //           this.sharedService.showMessageDialog(
  //             '', 'File uploaded successfully',
  //             SweetAlertIcon.SUCCESS
  //           );
  //           this.modalService.hide();
  //           console.log("Success:", result);
  //           // Handle success, e.g., show a message to the user
  //         } else {
  //           console.log('Binary data received:', blob);
  //           const url = window.URL.createObjectURL(blob);
  //           const a = document.createElement('a');
  //           a.href = url;
  //           a.download = filename;
  //           document.body.appendChild(a);
  //           a.click();
  //           document.body.removeChild(a);
  //           window.URL.revokeObjectURL(url);
  //           this.output.emit('renderNewData');
  //           this.modalService.hide();
  //           this.sharedService.showMessageDialog(
  //             'There is a problem with your uploaded file',
  //             'Please check the downloaded file for status',
  //             SweetAlertIcon.ERROR
  //           );
  //           this.loaderService.setLoading(false);
  //           console.log("Unexpected response:", result);
  //         }
  //       };
  //       reader.readAsText(response.body);


  //       /*  if (blob) {
         
  //         console.log('Binary data received:', blob);
  //         const url = window.URL.createObjectURL(blob);
  //         const a = document.createElement('a');
  //         a.href = url;
  //         a.download = filename;
  //         document.body.appendChild(a);
  //         a.click();
  //         document.body.removeChild(a);
  //         window.URL.revokeObjectURL(url);
  //         this.output.emit('renderNewData');
  //         this.modalService.hide();
  //         this.sharedService.showMessageDialog(
  //           'File has uploaded',
  //           'Please check the downloaded file for status',
  //           SweetAlertIcon.SUCCESS
  //         );
  //         this.loaderService.setLoading(false);
  //       } else {
  //         console.error('Blob is null');
  //       } */
  //     },
  //     error: (err) => {
  //       this.loaderService.setLoading(false);
  //       console.log('err', err);
  //     },
  //   });
  // }



  uploadFile() {
    this.loaderService.setLoading(true);
    console.log(this.uploadSaveUrl);
    let url = '';
    if (
      this.pageData.title.toUpperCase() === this.pageId.toUpperCase() &&
      this.requiredProjectId
    ) {
      url = this.url + this.pageData.projectId;
    }
  
    this.sharedService.bulkUpload(url, this.file).subscribe({
      next: (response: any) => {
        console.log("Response received:", response);
        this.loaderService.setLoading(false);
        const blob = response.body;
        const contentDisposition = response.headers.get('Content-Disposition');
        let filename = this.templateTitle; // Default filename
  
        if (blob instanceof Blob) {
          // If the response is a Blob, try reading it as text to check for a JSON message
          const reader = new FileReader();
          reader.onload = () => {
            const result: any = reader.result;
            try {
              const parsedResult = JSON.parse(result);
              if (parsedResult?.statusCode === 200 && parsedResult?.message === "File processed successfully.") {
                this.sharedService.showMessageDialog(
                  '',
                  'File uploaded successfully',
                  SweetAlertIcon.SUCCESS
                );
                this.modalService.hide();
                this.output.emit('renderNewData');
                console.log("Success:", parsedResult.message);
              } else if (parsedResult?.statusCode === 400 && parsedResult?.message) {
                this.sharedService.showMessageDialog(
                  'Upload Failed',
                  parsedResult.message,
                  SweetAlertIcon.ERROR
                );
                console.log("Error:", parsedResult.message);
              } else {
                // If the response doesn't match expected JSON, treat as binary data
                this.handleBlobDownload(blob, filename);
              }
            } catch (e) {
              // If parsing fails, treat it as binary data
              console.error("Failed to parse response as JSON:", e);
              this.handleBlobDownload(blob, filename);
            }
          };
          reader.readAsText(blob);
        } else if (response?.statusCode && response?.message) {
          // Handle JSON response directly
          if (response.statusCode === 200 && response.message === "File processed successfully.") {
            this.sharedService.showMessageDialog(
              '',
              'File uploaded successfully',
              SweetAlertIcon.SUCCESS
            );
            this.modalService.hide();
            this.output.emit('renderNewData');
            console.log("Success:", response.message);
          } else if (response.statusCode === 400 && response.message) {
            this.sharedService.showMessageDialog(
              'Upload Failed',
              response.message,
              SweetAlertIcon.ERROR
            );
            console.log("Error:", response.message);
          }
        } else {
          console.error("Unexpected response type:", typeof response.body);
        }
      },
      error: (err) => {
        this.loaderService.setLoading(false);
        console.log('Error:', err);
        this.sharedService.showMessageDialog(
          'Upload failed',
          'There was an issue with the file upload. Please try again.',
          SweetAlertIcon.ERROR
        );
      },
    });
  }
  
  // Helper method to handle Blob download
  private handleBlobDownload(blob: Blob, filename: string) {
    console.log('Downloading file:', filename);
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    this.output.emit('renderNewData');
    this.modalService.hide();
    this.sharedService.showMessageDialog(
      'There is a problem with your uploaded file',
      'Please check the downloaded file for status',
      SweetAlertIcon.ERROR
    );
    this.loaderService.setLoading(false);
  }
  
  
  
  ngOnInit(): void {
    type TemplateKeys = keyof typeof Templates;

    const key = this.pageData.title.toUpperCase() as TemplateKeys;

    if (key in Templates) {
      this.templateTitle = Templates[key].template_name;
      this.pageId = Templates[key].id;
      this.requiredProjectId = Templates[key]?.requiredProjectId;
      this.url = Templates[key]?.url;
    } else {
      // Handle other cases or set a default value
      this.templateTitle = '';
      this.pageId = '';
    }
  }
}
