export enum SweetAlertIcon {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
  QUESTION = 'question',
}

export enum Roles {
  ADMIN = 'Admin',
  SUPERADMIN = 'SuperAdmin',
  CONSUMER = 'Consumer',
  AGENT = 'Agent',
}

export enum BillingType {
  PREPAID = 'Prepaid',
  POSTPAID = 'Postpaid',
}

export enum UtilityType {
  WATER = 'Water',
  ELECTRICITY = 'Electricity',
  GAS = 'Gas',
  EV = 'EV',
}

export enum McbButtons {
  On = 'On',
  Off = 'Off',
}

export const batteryLevel = [
  { id: 'all', name: 'All ' },
  { id: 'normal', name: 'Normal' },
  { id: 'low', name: 'Low' },
];

export const superadmin_graphColors = [
  '#F9AF58', // Orange shade
  '#6ED972', // Green shade
  '#3498db', // Example: Blue shade
  '#e74c3c', // Example: Red shade
];

export const monthColors: any = {
  January: '#FADF89',
  February: '#F9AF58',
  March: '#F37E53',
  April: '#E96360',
  May: '#DB6FA0',
  June: '#AD8AC0',
  July: '#6480BC',
  August: '#63AEDB',
  September: '#44C8F5',
  October: '#60C5B4',
  November: '#78C68B',
  December: '#AFCC40',
};

export enum notificationType {
  FEEDBACK = 'Feedback',
}

export const NOTIFICATIONS_ICONS = {
  Feedback: {
    url: "../../../assets/notifications/feedback.svg",
    type: "Feedback",
    redirectUrl: "/feedbackHistory",
    icon: "fas fa-handshake",
    color: "#E96360",
  },
  Gateway_Status: {
    url: "../../../assets/notifications/gateway.svg",
    type: "Gateway_Status",
    redirectUrl: null,
    icon: "mdi mdi-gate",
    color: "#607D8B", // Grey color indicating gateway status
  },
  Electricity_Source_Shifted: {
    url: "../../../assets/notifications/energy.svg",
    type: "Electricity_Source_Shifted",
    redirectUrl: null,
    icon: "mdi mdi-flash",
    color: "#FFA726", // Amber color for electricity-related notification
  },
  Recharge_successful: {
    url: "../../../assets/notifications/wallet.svg",
    type: "Recharge_successful",
    redirectUrl: "/rechargeHistory",
    icon: "mdi mdi-book-refresh-outline",
    color: "#6480BC",
  },
  Recharge_failed: {
    url: "../../../assets/notifications/rechargefailure.svg",
    type: "Recharge_failed",
    redirectUrl: "/rechargeHistory",
    icon: "mdi mdi-book-refresh-outline",
    color: "#6480BC",
  },
  Meter_On_Off_Notification: {
    url: "../../../assets/notifications/onoffmeter.svg",
    type: "Meter_On_Off_Notification",
    redirectUrl: '/meterContolls',
    icon: 'mdi mdi-car-cruise-control',
    color: "#2ECC71", // A green color indicating power or activation
  },

  Invoice_Generation: {
    url: "../../../assets/notifications/invoice.svg",
    type: "Invoice_Generation",
    redirectUrl: "/billInvoices",
    icon: 'bx bx-receipt',
    color: "#E96360",
  },
  Bill_Generation: {
    url: "../../../assets/notifications/bill.svg",
    type: "Bill_Generation",
    redirectUrl: "/postpaidBill",
    icon: 'mdi mdi-file-document-multiple',
    color: "#FF9800", // Orange color for bills
  },

  Bill_Payment_success: {
    url: "../../../assets/notifications/billstatusSuccess.svg",
    type: "Bill_Payment_success",
    redirectUrl: "/billHistory",
    icon: 'mdi mdi-file-clock-outline',
    color: "#F37E53",
  },
  Bill_Payment_failure: {
    url: "../../../assets/notifications/Bill_Payment_failure.svg",
    type: "Bill_Payment_failure",
    redirectUrl: "/billHistory",
    icon: 'mdi mdi-file-clock-outline',
    color: "#F37E53",
  },
  Non_Communicating_Meters: {
    url: "../../../assets/notifications/Non_Communicating_Meters.svg",
    type: "Non_Communicating_Meters",
    redirectUrl: null,
    icon: "mdi mdi-wifi-off",
    color: "#9E9E9E", // Grey color for non-communication
  },
  Meter_MCB_Turned_On: {
    url: "../../../assets/notifications/Meter_MCB_Turned_On.svg",
    type: "Meter_MCB_Turned_On",
    redirectUrl: '/utilities',
    icon: 'fas fa-boxes',
    color: "#F44336", // Red color indicating power off
  },

  Low_Recharge_Alert: {
    url: "../../../assets/notifications/low_recharge.svg",
    type: "Low_Recharge_Alert",
    redirectUrl: "/consumerWallet",
    icon: 'dripicons-wallet',
    color: "#6480BC",
  },
  High_Consumption_Alert_GAS: {
    url: "../../../assets/notifications/High_Consumption_alert_GAS.svg",
    type: "High_Consumption_Alert_GAS",
    redirectUrl: "/consumptionHistory",
    icon: 'dripicons-wallet',
    color: "#63AEDB",
  },
  High_Consumption_Alert_Water: {
    url: "../../../assets/notifications/high_consumption_water.svg",
    type: "High_Consumption_Alert_Water",
    redirectUrl: "/consumptionHistory",
    icon: 'dripicons-wallet',
    color: "#63AEDB",
  },
  High_Consumption_Alert_EB: {
    url: "../../../assets/notifications/high_consumption_eb.svg",
    type: "High_Consumption_Alert_EB",
    redirectUrl: "/consumptionHistory",
    icon: 'dripicons-wallet',
    color: "#63AEDB",
  },
  High_Consumption_Alert_DG: {
    url: "../../../assets/notifications/high_consumption_dg.svg",
    type: "High_Consumption_Alert_DG",
    redirectUrl: "/consumptionHistory",
    icon: 'dripicons-wallet',
    color: "#63AEDB",
  },
  Meter_on_request: {
    url: "../../../assets/notifications/Meter_on_request.svg",
    type: "Meter_on_request",
    redirectUrl: "",
    icon: 'dripicons-wallet',
    color: "#63AEDB",
  },
  // BILL_STATUS: {
  //   url: '../../../assets/notifications/billstatus.svg',
  //   type: 'BILL_STATUS',
  //   redirectUrl: '/billHistory',
  //   icon: 'mdi mdi-file-check',
  //   color: '#4CAF50' // Green color indicating status check
  // },

  // MCB_OFF: {
  //   url: '../../../assets/notifications/invoice.svg',
  //   type: 'MCB_OFF',
  //   redirectUrl: null,
  //   icon: 'mdi mdi-power-plug-off',
  //   color: '#F44336' // Red color indicating power off
  // },
};


export const Months = [
  {
    "id": "January",
    "name": "January"
  },
  {
    "id": "February",
    "name": "February"
  },
  {
    "id": "March",
    "name": "March"
  },
  {
    "id": "April",
    "name": "April"
  },
  {
    "id": "May",
    "name": "May"
  },
  {
    "id": "June",
    "name": "June"
  },
  {
    "id": "July",
    "name": "July"
  },
  {
    "id": "August",
    "name": "August"
  },
  {
    "id": "September",
    "name": "September"
  },
  {
    "id": "October",
    "name": "October"
  },
  {
    "id": "November",
    "name": "November"
  },
  {
    "id": "December",
    "name": "December"
  }
]


export const Active_Status = [
  { id: "all", name: "All" },
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" }
];

export const Mcb_Status = [
  { id: "all", name: "All" },
  { id: "On", name: "On" },
  { id: "Off", name: "Off" }
];

export const Valve_Status = [
  { id: "all", name: "All" },
  { id: "On", name: "On" },
  { id: "Off", name: "Off" }
];

export const Communication_Status = [
  { id: "all", name: "All" },
  { id: "Yes", name: "Yes" },
  { id: "No", name: "No" }
];

export const BillStatus = [
  { id: "all", name: "All" },
  { id: "Paid", name: "Paid" },
  { id: "Un Paid", name: "Un Paid" }
];


export const WalletBalanceStatus = [
  { id: "all", name: "All" },
  { id: "Success", name: "Success" },
  { id: "InProcess", name: "In Process" },
  { id: "Failure", name: "Failure" },
];